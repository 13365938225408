import {
  Careers,
  About,
  Investors,
  Partner,
  Sustainability,
} from '@/components/assets/IconNavigationSignposts/IconNavigationSignposts';
import {
  IconAttractingIinspiringAndInvestingInPeople,
  IconCreatingSustainableHomesAndCommunities,
  IconEnvironmentallyConsiderateAndEfficientOperations,
  IconKeepingPeopleSafe,
  IconPuttingCustomersAtTheHeartOfWhatWeDo,
  IconSustainableAndResponsibleSourcing,
} from '@/components/assets/SustainabilityPillarsIcons/SustainabilityPillarsIcons';

const iconVariant = (icon) => {
  return {
    About: <About />,
    Careers: <Careers />,
    Investors: <Investors />,
    Partner: <Partner />,
    Sustainability: <Sustainability />,
    puttingCustomersAtTheHeartOfWhatWeDo: (
      <IconPuttingCustomersAtTheHeartOfWhatWeDo color="#004D69" />
    ),
    attractingInspiringAndInvestingInPeople: (
      <IconAttractingIinspiringAndInvestingInPeople color="#004D69" />
    ),
    keepingPeopleSafe: <IconKeepingPeopleSafe color="#004D69" />,
    creatingSustainableHomesAndCommunities: (
      <IconCreatingSustainableHomesAndCommunities color="#004D69" />
    ),
    environmentallyConsiderateAndEfficientOperations: (
      <IconEnvironmentallyConsiderateAndEfficientOperations color="#004D69" />
    ),
    sustainableAndResponsibleSourcing: (
      <IconSustainableAndResponsibleSourcing color="#004D69" />
    ),
  }[icon];
};
export default iconVariant;
