import React from 'react';

import styles from './IconNavigationSignposts.module.scss';

export const About = ({color='#004D69'}) => {
  return (
    <svg
      className={styles.size}
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
    >
      <path
        className={styles.size}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6777 20.4668C22.061 20.4668 23.9941 18.5338 23.9941 16.1513C23.9941 13.7671 22.061 11.835 19.6777 11.835C17.2944 11.835 15.3613 13.7671 15.3613 16.1513C15.3613 18.5338 17.2944 20.4668 19.6777 20.4668Z"
        stroke={color}
        strokeWidth="1.63902"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9634 26.0268C24.2937 23.6759 22.1505 22.0488 19.7057 22.0362C17.261 22.0236 15.1024 23.629 14.4111 25.9736"
        stroke={color}
        strokeWidth="1.63902"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0449 29.1316H28.3104"
        stroke={color}
        strokeWidth="1.63902"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9687 37.7294L12.6143 29.1318H26.7401L24.3866 37.7294"
        stroke={color}
        strokeWidth="1.63902"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6719 32.6638C19.4553 32.6638 19.2794 32.838 19.2794 33.0564C19.2794 33.2721 19.4553 33.4481 19.6719 33.4481C19.8876 33.4481 20.0645 33.2721 20.0645 33.0564C20.0645 32.838 19.8876 32.6638 19.6719 32.6638"
        stroke={color}
        strokeWidth="1.63902"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46952 25.1756H3.20208C2.33574 25.1756 1.63184 24.4744 1.63184 23.6062V3.20118C1.63184 2.33393 2.33574 1.63184 3.20208 1.63184H36.1519C37.0201 1.63184 37.7222 2.33393 37.7222 3.20118V23.6062C37.7222 24.4744 37.0201 25.1756 36.1519 25.1756H29.8737"
        stroke={color}
        strokeWidth="1.63902"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Investors = ({color='#004D69'}) => {
  return (
    <svg
      className={styles.size}
      width="39"
      height="41"
      viewBox="0 0 39 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.542 39.7149L12.5042 35.2623C11.5773 34.1446 11.7291 32.4838 12.8448 31.5554C13.9596 30.626 15.6151 30.7782 16.542 31.8959L20.0418 35.9082V22.163C20.0418 20.7105 21.2182 19.531 22.6679 19.531C24.1184 19.531 25.293 20.7105 25.293 22.163V30.061H30.5441C34.4111 30.061 37.5457 33.2038 37.5457 37.0801V39.713"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9168 26.2353C7.1505 26.2353 1.66406 20.7354 1.66406 13.9503C1.66406 7.16526 7.1505 1.66626 13.9168 1.66626C20.684 1.66626 26.1694 7.16526 26.1694 13.9503"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3849 9.57129L9.53613 17.4407"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2978 10.1752C12.2978 11.2358 11.4411 12.0957 10.3833 12.0957C9.32639 12.0957 8.46875 11.2358 8.46875 10.1752C8.46875 9.11454 9.32639 8.25464 10.3833 8.25464C11.4411 8.25464 12.2978 9.11454 12.2978 10.1752Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6435 16.5363C18.6435 17.5969 17.7858 18.4568 16.729 18.4568C15.6721 18.4568 14.8145 17.5969 14.8145 16.5363C14.8145 15.4766 15.6721 14.6167 16.729 14.6167C17.7858 14.6167 18.6435 15.4766 18.6435 16.5363Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Sustainability = ({color='#004D69'}) => {
  return (
    <svg
      className={styles.size}
      width="41"
      height="43"
      viewBox="0 0 41 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6372 22.241C39.6372 32.985 30.9927 41.6952 20.3274 41.6952C9.66309 41.6952 1.01758 32.985 1.01758 22.241H39.6372Z"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5497 22.241V24.0094C11.5497 25.963 13.1213 27.5464 15.0614 27.5464C16.9994 27.5464 18.5721 29.1298 18.5721 31.0833C18.5721 33.0369 16.9994 34.6213 15.0614 34.6213H5.42969"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5304 31.0837H30.8603C28.9212 31.0837 27.3496 32.6681 27.3496 34.6217V40.3695"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.79474 18.7037V8.97656"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.3708 11.6297L20.327 1.01685L6.2832 11.6297"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6943 18.7035V14.2818C17.6943 12.8174 18.8733 11.6296 20.3279 11.6296C21.7814 11.6296 22.9604 12.8174 22.9604 14.2818V18.7035"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5938 4.99666V1.01733H30.8598V18.7041"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const Partner = ({color='#004D69'}) => {
  return (
    <svg
      className={styles.size}
      width="46"
      height="28"
      viewBox="0 0 46 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9035 7.07635L21.1651 9.27874C19.9854 9.65733 18.7216 9.01367 18.3409 7.84113C18.0022 6.79606 18.4786 5.66094 19.4638 5.16503L25.993 1.91947C27.1192 1.36396 28.4239 1.2923 29.6049 1.72035L39.2421 5.19737V18.3521L27.8927 20.4962"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.11523 18.3521H11.8398L18.6241 25.9143C19.5577 26.9923 21.194 27.1141 22.278 26.1863C22.85 25.6961 23.1791 24.9827 23.1784 24.2319V23.0505L23.5164 23.1855C25.1578 23.8368 27.0185 23.0422 27.6747 21.4106C27.8258 21.0351 27.903 20.6356 27.903 20.2317H28.848C30.4129 20.2317 31.6825 18.9691 31.6825 17.4129C31.6825 16.8022 31.4829 16.2086 31.1156 15.7216L25.0679 8.01611"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4425 4.66916L19.7512 4.10541C18.6238 3.27467 17.1437 3.08316 15.8397 3.59999L7.11621 7.07638"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.44629 3.3186H5.22646C6.26971 3.3186 7.11591 4.15948 7.11591 5.19758V18.3523C7.11591 19.3898 6.26971 20.2319 5.22646 20.2319H1.44629"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.9118 20.2317H41.1316C40.0884 20.2317 39.2422 19.3895 39.2422 18.3521V5.19733C39.2422 4.15924 40.0884 3.31836 41.1316 3.31836H44.9118"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1785 23.0508L21.2891 21.1711"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.0684 17.4128L27.9029 20.2316"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const Careers = ({color='#004D69'}) => {
  return (
    <svg
      className={styles.size}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8404 33.8963C26.7079 33.8963 33.8966 26.7076 33.8966 17.8391C33.8966 8.97055 26.7079 1.78296 17.8404 1.78296C8.97191 1.78296 1.7832 8.97055 1.7832 17.8391C1.7832 26.7076 8.97191 33.8963 17.8404 33.8963Z"
        stroke={color}
        strokeWidth="1.89412"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.7135 38.7095L29.1934 29.1882"
        stroke={color}
        strokeWidth="1.89412"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3323 26.0793V29.9676"
        stroke={color}
        strokeWidth="1.89412"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.505 27.0551C23.412 24.0576 20.5217 22.0984 17.3331 22.1956C14.1433 22.0984 11.2542 24.0576 10.1611 27.0551"
        stroke={color}
        strokeWidth="1.89412"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3076 10.4912C15.1062 13.3904 19.4091 14.2554 23.1119 12.6606"
        stroke={color}
        strokeWidth="1.89412"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3327 19.2762C20.5527 19.2762 23.1646 16.6665 23.1646 13.4443C23.1646 10.2232 20.5527 7.61353 17.3327 7.61353C14.1128 7.61353 11.502 10.2232 11.502 13.4443C11.502 16.6665 14.1128 19.2762 17.3327 19.2762Z"
        stroke={color}
        strokeWidth="1.89412"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
