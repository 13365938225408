import { string, shape, arrayOf } from 'prop-types';
import React from 'react';

import ClickAction from '@/components/ClickAction';
import IconArrow, { COLOUR } from '@/components/assets/IconArrow/IconArrow';

import iconVariant from './dataIcons.js';

import styles from './navigationSignposts.module.scss';

export default function NavigationSignposts(props) {
  const { title, listIcons } = props;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
      </div>
      <div className={styles.cards}>
        {listIcons.map((card) => (
          <div className={styles.card} key={card.title}>
            <div className={styles.iconMain}>{iconVariant(card.variant)}</div>
            <div className={styles.text}>
              <h4>{card.title}</h4>
              <p>{card.description}</p>
            </div>
            <div className={styles.buttonWrapper}>
              <ClickAction
                action={card.clickAction}
                url={card.url}
                section="Signpost card"
              >
                <div className={styles.iconArrow}>
                  <IconArrow color={COLOUR.BLUE} size="inherit" />
                </div>
              </ClickAction>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

NavigationSignposts.propTypes = {
  title: string,

  listIcons: arrayOf(
    shape({
      variant: string,
      description: string,
      title: string,
      url: string,
    }),
  ),
};

NavigationSignposts.defaultProps = {
  title: '',
  listIcons: [{ description: '', variant: '', title: '', url: '/' }],
};
